import React from "react"
import Container from "../components/Container"
import H1 from "../components/H1"
import H2 from "../components/H2"
import HeroHeader from "../components/HeroHeader"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

const ResumePage = ({ data }) => (
  <Layout>
    <SEO
      title="Steven Musumeche Resume | Senior Software Engineer | Lafayette, LA"
      keywords={[`todo`]}
    />
    <HeroHeader textAlign="left">
      <Container>
        <H1>Resume</H1>
        <H2>
          Accomplished full-stack senior software engineer, currently focusing
          on Node.js, GraphQL, ReactJS, and the general JavaScript ecosystem.
        </H2>
      </Container>
    </HeroHeader>
    <Container></Container>
  </Layout>
)

export default ResumePage

export const query = graphql`
  {
    markdownRemark(frontmatter: { id: { eq: "resume" } }) {
      html
    }
  }
`
